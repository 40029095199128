// import axios from 'axios';
import { apiPost, apiGet } from './index'

export function gethome() {
	return apiGet("/api/home/index");
}


export function login(data) {
	return apiPost("/api/auth/login", data);
}

export function logout() {
	return apiPost("/api/auth/logout");
}

export function enable2fa() {
	return apiPost("/api/enable-2fa");
}
