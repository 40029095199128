<template>
    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-dark-primary elevation-4">

        <!-- Sidebar -->
        <div class="sidebar">
            <!-- Sidebar user panel (optional) -->
            <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                <div class="image">
                    <img src="@/assets/images/sun.png" class="img-circle elevation-2" alt="User Image">
                </div>
                <div class="info">
                    <a href="#" class="d-block">晴鈦朗光電</a>
                </div>
            </div>

            <!-- Sidebar Menu -->
            <nav class="mt-2">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                    data-accordion="false">
                    <li class="nav-item" v-for="item in adminMenu" :key="item.powerid">

                        <a :href="item.to" class="nav-link">
                            <i class="nav-icon far fa-envelope"></i>
                            <p>
                                {{ item.name }}
                                <i v-if="item.child" class="right fas fa-angle-left"></i>
                            </p>
                        </a>

                        <ul v-if="item.child" class="nav nav-treeview">
                            <li class="nav-item" v-for="sub in item.child" :key="sub.powerid">
                                <a :href="sub.to" class="nav-link">
                                    <i class="far fa-circle nav-icon"></i>
                                    <p>{{ sub.name }}</p>
                                </a>
                            </li>
                        </ul>
                    </li>

                    <li class="nav-item">
                        <a @click="logout()" class="nav-link">
                            <i class="fa fa-sign-out nav-icon"></i>
                            <p>
                                登出帳號
                            </p>
                        </a>
                    </li>
                </ul>
            </nav>
            <!-- /.sidebar-menu -->
        </div>
        <!-- /.sidebar -->
    </aside>
</template>

<script>
import nav from '@/support/_menu.js'
import { clearStore } from '@/support/cache.js'

export default {
    data() {
        return {
            adminMenu: nav
        };
    },
    beforeCreate() {

        if (nav == null) {
            console.log("不存在");
            return;
        }

        this.adminMenu = nav;
    },
    methods: {
        logout() {
            clearStore();
            this.$router.push({ name: "login" });
        }
    }
}
</script>

<style></style>