export function clearStore() {
    localStorage.clear();
}

export function getToken() {
    return localStorage.getItem('token'); 
}

export function setToken(token) {
    localStorage.setItem('token', token);
}



